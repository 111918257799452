<template>
    <div>
        <TopSection from="Home" page="My Account" />
        <div class="center-wrapper page-wrapper p-3">
          <div class="side-wrap">
            <TableSideBar :items="sideBarData" />
          </div>
          <div class="main-content">
            <div class="credit-outer">
              <div class="credit-inner">
                <div class="row">
                  <span class="bold">Your Credit Limit</span>
                  <span class="bold"><b>{{ creditLimit['CreditAmount'] ? `${creditLimit['CreditAmount'].toFixed(2)}` : '00' }} CAD</b></span>
                </div>
                <div class="row">
                  <span class="bold">Used Limit</span>
                  <span>{{ creditLimit['AmountUsed'] ? `${creditLimit['AmountUsed'].toFixed(2)}` : '00' }} CAD</span>
                </div>
                <div class="row">
                  <span class="bold">Balance Limit</span>
                  <span>{{ creditLimit['Balance'] ? `${creditLimit['Balance'].toFixed(2)}` : '00' }} CAD</span>
                </div>
                <div class="row">
                  <span class="bold">Credit Limit Duration</span>
                  <span>30 days from your purchase date</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import { mapState } from 'vuex'
import TopSection from '@/components/molecules/TopSection'
import TableSideBar from '@/components/molecules/TableSideBar'
import Common from '@/mixins/Common'
export default {
  mixins: [Common],
  components: {
    TopSection,
    TableSideBar
  },
  computed: {
    ...mapState('auth', ['creditLimit'])
  },
  metaInfo() {
    return {
      title: 'Credit Limit'
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/common'
.credit-outer
  display: flex
  justify-content: space-evenly
  .credit-inner
    width: 50%
  .row
    display: flex
    justify-content: space-between
    span
      @include font-source('Poppins', 13px, 400, $black1)
      &.bold
        font-weight: 600
</style>
