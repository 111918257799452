import { render, staticRenderFns } from "./CreditLimit.vue?vue&type=template&id=93a73e2e&scoped=true&"
import script from "./CreditLimit.vue?vue&type=script&lang=js&"
export * from "./CreditLimit.vue?vue&type=script&lang=js&"
import style0 from "./CreditLimit.vue?vue&type=style&index=0&id=93a73e2e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93a73e2e",
  null
  
)

export default component.exports